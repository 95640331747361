export const DEFAULT_NAV_ITEMS = [
  {
    text: "Elsie Insight Hub",
    route: "/",
    icon: "mdi-view-dashboard",
  },
  {
    text: "Admin panel",
    route: "/admin/users",
    icon: "mdi-security",
    conditions: {
      isSuperAdmin: true,
    },
  },
  {
    text: "Inbox",
    icon: "mdi-message-reply",
    route: "/inbox",
  },
  {
    text: "Campaigns",
    icon: "mdi-email-multiple",
    route: "/campaigns",
  },
  {
    text: "CRM",
    icon: "mdi-account-multiple",
    nested: [
      {
        text: "Pipeline",
        route: "/contacts/pipeline",
      },
      {
        text: "Contacts",
        route: "/contacts",
      },
    ],
  },
  {
    text: "Refer a friend",
    icon: "mdi-hand-heart",
    external: true,
    route: "https://linkedclient.tapfiliate.com",
  },
  {
    text: "Settings",
    icon: "mdi-wrench",
    nested: [
      {
        text: "Profile",
        route: "/profile",
      },
      {
        text: "Users",
        route: "/users",
        conditions: {
          isAdmin: true,
        },
      },
      {
        text: "Email Accounts",
        route: "/emails",
      },
      {
        text: "LinkedIn Accounts",
        route: "/linkedin/accounts",
      },
      {
        text: "Billing",
        customAction: "billing",
        conditions: {
          isAdmin: true,
        },
      },
    ],
  },
  {
    text: "Add seats",
    icon: "mdi-account-plus",
    route: "/users?addSeats=true",
  },
  {
    text: "Announcements",
    route: "/announcements",
    icon: "mdi-message-alert",
  },
  {
    text: "Integrations",
    new: true,
    route: "/integrations",
    icon: "mdi-puzzle",
  },
  {
    text: "Support",
    icon: "mdi-lifebuoy",
    customAction: "support",
  },
];

export const ADMIN_NAV_ITEMS = [
  {
    text: "Elsie Insight Hub",
    route: "/",
    icon: "mdi-view-dashboard",
  },
  {
    text: "Admin Dashboard",
    route: "/admin/dashboard",
    icon: "mdi-security",
  },
  {
    text: "Users",
    route: "/admin/users",
    icon: "mdi-account-group",
  },
  {
    text: "Organizations",
    route: "/admin/organizations",
    icon: "mdi-office-building",
  },
  {
    text: "Linkedin Accounts",
    route: "/admin/linkedin",
    icon: "mdi-linkedin",
  },
  {
    text: "Announcements",
    route: "/admin/announcements",
    icon: "mdi-message-alert",
  },
  {
    text: "Sales Representatives",
    route: "/admin/sales-reps",
    icon: "mdi-cash-100",
  },
  {
    text: "Onboardings",
    route: "/admin/onboardings",
    icon: "mdi-lifebuoy",
  },
  {
    text: "Custom signup",
    route: "/admin/custom-signup",
    icon: "mdi-draw",
  },
];
